<template>
    <div class="d-flex flex-column">
        <div :class="'node ' + nodeType + getNodeColor(stage)" :id="nodeId"><span>{{ stage }}</span></div>
        <span class="font-weight-bold" style="margin-top: 2px">{{ formatDate(stageDate, 'd/m/y') }}</span>
    </div>
</template>

<script>
import { formatDate } from "Helpers/helpers"

export default {
    name: "DealStageNode",
    props: ["nodeId", "stage", "stageDate", "nodeType"],
    methods: {
        formatDate,
        getNodeColor(stage) {
            switch(stage){
                case '1':
                    return ' white'
                    break;
                case '2':
                    return ' blue'
                    break;
                case '3':
                    return ' green'
                    break;
                case '4':
                    return ' amber'
                    break;
                case '0':
                    return ' red'
                    break;
                default:
                    return ''
            }
        }
    }
}
</script>

<style scoped>
.node {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    background-color: #3C6932;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.node.start {
    background-color: grey !important;
}
.node.end {
    background-color: grey !important;
}
.node span {
    color: white;
    font-size: 1.2em;
}
.node.white {
    background-color: white !important;
    border-color: black !important;
}
.node.blue {
    background-color: #1E88E5 !important;
    border-color: black !important;
}
.node.green {
    background-color: #558B2F !important;
    border-color: black !important;
}
.node.amber {
    background-color: #FFAB00 !important;
    border-color: black !important;
}
.node.red {
    background-color: red !important;
    border-color: black !important;
}
.node.white::v-deep span {
    color: black !important;
    font-weight: bold !important;
}
.node.amber::v-deep span {
    color: black !important;
    font-weight: bold !important;
}
</style>